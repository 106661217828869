import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import { Constant, FIELD_COMPONENT, MODULE_NAME } from 'app/config/constants';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogExportReservationComponent } from 'app/dialog/dialog-export-reservation/dialog-export-reservation.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { PriceTableComponent } from 'app/dialog/price-table/price-table.component';
import { Common } from 'app/model/entity/common';
import { Ticket } from 'app/model/entity/ticket';
import { ContentDayReservation } from 'app/model/entity/ticket-editor/content-day-reservation';
import { ApplicationDTO } from 'app/model/entity/ticket-editor/dto/application-DTO';
import { Stock } from 'app/model/entity/ticket-editor/stock';
import { StockPartition } from 'app/model/entity/ticket-editor/stock-partitions';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { TicketEditorService } from 'app/service/ticket-editor.service';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { DatePickerDirective } from 'ng2-date-picker';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
@Component({
  selector: 'ticket-editor-tab-reservation',
  templateUrl: './ticket-editor-tab-reservation.component.html',
  styleUrls: ['./ticket-editor-tab-reservation.component.scss']
})
export class TicketEditorTabReservationComponent implements OnInit {
  @ViewChild('tablelistTicket') tablelistTicket!: ElementRef;
  @ViewChild('tablelistReservationMaster') tablelistReservationMaster!: ElementRef;
  @Input() tabSelected: number; // tab selected
  @Input() informationAccount: any;
  Constant = Constant;
  MODULE_NAME = MODULE_NAME;
  FIELD_COMPONENT = FIELD_COMPONENT;
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;
  subscriptions: Array<Subscription> = new Array<Subscription>(); //array subscription
  currentDate: Date; // current date
  commonObject: Common; // common object
  selectedYear: number; // year selected
  selectedMonth: any; // month selected
  isNextMonth: boolean; // true if > finish month
  isPreviousMonth: boolean = true; // true if < start month
  isEnlargePreview: boolean = true; // true if preview on
  listOfReservedTickets: Array<Ticket> = new Array<Ticket>();
  inventoryClassificationSchedule: any;
  /**
   * list month
   */
  listMonth: Array<{ value: string; key: number }> = Array(
    { value: this.translateService.instant('schedule-registration.month-1'), key: 0 },
    { value: this.translateService.instant('schedule-registration.month-2'), key: 1 },
    { value: this.translateService.instant('schedule-registration.month-3'), key: 2 },
    { value: this.translateService.instant('schedule-registration.month-4'), key: 3 },
    { value: this.translateService.instant('schedule-registration.month-5'), key: 4 },
    { value: this.translateService.instant('schedule-registration.month-6'), key: 5 },
    { value: this.translateService.instant('schedule-registration.month-7'), key: 6 },
    { value: this.translateService.instant('schedule-registration.month-8'), key: 7 },
    { value: this.translateService.instant('schedule-registration.month-9'), key: 8 },
    { value: this.translateService.instant('schedule-registration.month-10'), key: 9 },
    { value: this.translateService.instant('schedule-registration.month-11'), key: 10 },
    { value: this.translateService.instant('schedule-registration.month-12'), key: 11 }
  );
  @Output() sizePreview = new EventEmitter(); // Send event size preview to parent component
  appIdSelected: string; // app Selected
  listApp: ApplicationDTO[] = []; // list Application
  public languageKey: string;
  ticketSelected: Ticket;
  config: any;
  private readonly FORMAT_DATE = 'YYYY-MM-DD';
  targetYearAndMonth: any;
  timeFromDisplay: string;
  contentDaysMonth: Array<ContentDayReservation>;
  listStockPartitions: Array<StockPartition> = new Array<StockPartition>();
  stockPartitionSelected: StockPartition;
  listStock: Array<Stock> = new Array<Stock>();
  languages: any = new Array<any>();
  languageSelected: string;

  constructor(
    private menuActionService: MenuActionService,
    public translateService: TranslateService,
    private dialogService: DialogService,
    private ticketEditorService: TicketEditorService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private dataService: DataService
  ) {
    this.subscriptions.push(
      this.menuActionService.actionImportReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.RESERVATION_ENUM) {
          this.importReservation();
        }
      }),
      this.menuActionService.actionExportReservation.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent] && this.tabSelected == Constant.RESERVATION_ENUM) {
          this.exportReservation();
        }
      }),
      this.translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
        this.listMonth = Array(
          { value: this.translateService.instant('timetable-editor.month-1'), key: 0 },
          { value: this.translateService.instant('timetable-editor.month-2'), key: 1 },
          { value: this.translateService.instant('timetable-editor.month-3'), key: 2 },
          { value: this.translateService.instant('timetable-editor.month-4'), key: 3 },
          { value: this.translateService.instant('timetable-editor.month-5'), key: 4 },
          { value: this.translateService.instant('timetable-editor.month-6'), key: 5 },
          { value: this.translateService.instant('timetable-editor.month-7'), key: 6 },
          { value: this.translateService.instant('timetable-editor.month-8'), key: 7 },
          { value: this.translateService.instant('timetable-editor.month-9'), key: 8 },
          { value: this.translateService.instant('timetable-editor.month-10'), key: 9 },
          { value: this.translateService.instant('timetable-editor.month-11'), key: 10 },
          { value: this.translateService.instant('timetable-editor.month-12'), key: 11 }
        );
        if (this.languages && this.languages.length) {
          this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
        }
        this.updateConfig();
      })
    );
    this.commonObject = this.commonService.getCommonObject();
    this.currentDate = Helper.getCurrentByTimezoneSetting(this.commonObject);
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (this.languages && this.languages.length) {
          //lấy LanguageCode từ app và sắp xếp
          const languagesCodes = this.languages.map(e => e.translation_language_code);
          const newlanguagesCodes = Helper.getLanguagesCode(languagesCodes, this.commonService);
          this.languages = newlanguagesCodes.map(code => Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code));
          //Sắp xếp LanguageCode (để languageSelected lên đầu)
          this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
        }
      }
    });
  }
  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language;
    this.languageSelected = this.languageKey == 'en' ? 'en' : 'ja';
    await this.getAllApplication();
    if (this.listApp && this.listApp.length > 0) {
      this.appIdSelected = _.cloneDeep(this.listApp[Constant.FIRST_ELEMENT_INDEX]?.appId);
      const newlanguagesCodes = Helper.getLanguagesCode(this.listApp[Constant.FIRST_ELEMENT_INDEX].supportedLanguage, this.commonService);
      this.languages = newlanguagesCodes.map(code => Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code));
      this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
      await this.getListTicketsWithReservations(this.listApp[Constant.FIRST_ELEMENT_INDEX]?.appId);
      if (this.listOfReservedTickets?.length) {
        this.selectTicket(this.listOfReservedTickets[Constant.FIRST_ELEMENT_INDEX]);
      }
    }
    this.selectedYear = this.currentDate.getFullYear();
    this.selectedMonth = this.currentDate.getMonth();
    this.contentDaysMonth = _.cloneDeep(Helper.getOneMonthCalendar(this.selectedMonth, this.selectedYear, this.commonObject));
    this.updateConfig();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /**
   * enlarge preview
   */
  changeSizePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.sizePreview.emit(this.isEnlargePreview);
  }

  /**
   * upload excel
   * @param event
   */
  async uploadExcel(event): Promise<void> {
    const selectedFiles: File[] = event.target.files;
    if (selectedFiles?.length > 1) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('ticket-editor.reservation.multi-file')
        }
      });
      this.resetInputFileValue();
      return;
    }

    const typeFiles = ['xlsx'];
    for (const file of selectedFiles) {
      const typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      if (!typeFiles.includes(typeName)) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('dialog-error.title'),
            text: this.translateService.instant('ticket-editor.msg.invalid-file')
          }
        });
        this.resetInputFileValue();
        return;
      }
    }

    try {
      const base64String = await this.convertFileToBase64(selectedFiles[0]);
      let payloadFile = {
        file: base64String,
        uploadFileType: 'reservation_master'
      };

      const filePath = await new Promise<any>((resolve, reject) => {
        this.ticketEditorService.importReservation(this.informationAccount, payloadFile).subscribe(resolve, reject);
      });

      this.ticketEditorService
        .updateReservationMaster(this.informationAccount, filePath?.filePath, Constant.RESERVATION_MASTER_VALIDATE)
        .subscribe(async res => {
          if (!res) {
            return;
          }
          const date = `${res['year']}-${res['month'].toString().padStart(2, '0')}-01`;
          this.dialogService.showDialog(
            DialogConfirmComponent,
            {
              data: {
                title: this.translateService.instant('ticket-editor.reservation.import-label'),
                text: `${this.translateService.instant(
                  `ticket-editor.reservation.text-confirm-import-reservation-master`
                )}${`\n\n`}${this.translateService.instant(`ticket-editor.reservation.target-year-and-month`)}${
                  this.languageKey == 'jp' ? '\t\t\t' : '\t'
                } ${
                  this.convertTimeDisplayLabel(date, this.languageKey) !== '' ? this.convertTimeDisplayLabel(date, this.languageKey) : ''
                }${`\n`}${this.translateService.instant(`ticket-editor.reservation.individual-ticket-id-header-table`)}${`\t\t `}${
                  res['ticketId']
                }
                `,
                button1: this.translateService.instant('ticket-editor.reservation.import-label'),
                button2: this.translateService.instant('ticket-editor.cancel')
              }
            },
            async result => {
              if (!result) {
                return;
              }
              await this.ticketEditorService
                .updateReservationMaster(this.informationAccount, filePath?.filePath, Constant.RESERVATION_MASTER_UPDATE)
                .subscribe(async res => {
                  const ticketSelectedClone = _.cloneDeep(this.ticketSelected);
                  const targetYearAndMonthClone = _.cloneDeep(this.targetYearAndMonth);
                  await this.fillDataReservationMaster();
                  let indexTicketBeforeChange = this.listOfReservedTickets.findIndex(e => e.ticketId == ticketSelectedClone.ticketId);
                  this.ticketSelected = _.cloneDeep(this.listOfReservedTickets[indexTicketBeforeChange]);
                  this.targetYearAndMonth = _.cloneDeep(targetYearAndMonthClone);
                  if (this.targetYearAndMonth) {
                    await this.onDateChange();
                  }
                  this.dialogService.showDialog(
                    DialogMessageComponent,
                    {
                      data: {
                        title: this.translateService.instant('ticket-editor.msg.success'),
                        textHasLineBreaks: `${this.translateService.instant(
                          `ticket-editor.msg.import-success`
                        )}${'\n\n'}${this.translateService.instant(`ticket-editor.reservation.target-year-and-month`)}${
                          this.languageKey == 'jp' ? '\t\t\t' : '\t'
                        } ${
                          this.convertTimeDisplayLabel(date, this.languageKey) !== ''
                            ? this.convertTimeDisplayLabel(date, this.languageKey)
                            : ''
                        }${`\n`}${this.translateService.instant(`ticket-editor.reservation.individual-ticket-id-header-table`)}${`\t\t `}${
                          res['ticketId']
                        }
                        `
                      }
                    },
                    result => {}
                  );
                });
            }
          );
        });
    } catch (error) {
      console.error(error);
    } finally {
      this.resetInputFileValue();
    }
  }

  /**
   * reset input file value
   */
  resetInputFileValue(): void {
    const element = document.getElementById('importedFileScheduleReservation') as HTMLInputElement;
    if (element) {
      element.value = null;
    }
  }

  /**
   * Handle show error message from server when import
   *
   * @param error
   */
  handleShowErrorMessageFromServerWhenImport(error: any): void {}

  /**
   * getTitleSelected
   * @param appId
   * @returns
   */
  getTitleSelected(appId: string): string {
    let indexApp = this.listApp.findIndex(e => e.appId == appId);
    if (indexApp == -1) {
      return '';
    } else {
      return this.listApp[indexApp].appName;
    }
  }

  async fillDataReservationMaster(): Promise<void> {
    this.targetYearAndMonth = undefined;
    this.timeFromDisplay = undefined;
    await this.getListTicketsWithReservations(this.appIdSelected);
    if (this.listOfReservedTickets?.length) {
      this.selectTicket(this.listOfReservedTickets[Constant.FIRST_ELEMENT_INDEX]);
      this.tablelistTicket.nativeElement.scrollTop = 0;
    } else {
      this.ticketSelected = undefined;
    }
    this.listStockPartitions = undefined;
    this.contentDaysMonth = _.cloneDeep(Helper.getOneMonthCalendar(this.selectedMonth, this.selectedYear, this.commonObject));
    this.stockPartitionSelected = undefined;
    this.listStock = undefined;
    let indexApp = this.listApp?.findIndex(e => e.appId == this.appIdSelected);
    if (indexApp !== -1) {
      const newlanguagesCodes = Helper.getLanguagesCode(this.listApp[indexApp].supportedLanguage, this.commonService);
      this.languages = newlanguagesCodes.map(code => Constant.LANGUAGES_SETTING.find(lang => lang.translation_language_code === code));
      this.languages = Helper.reorderLanguages(this.languages, this.languageSelected);
    }
  }

  /**
   * get list application
   * @param appSelect
   */
  async getAllApplication(): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketEditorService.findAllApplication(this.informationAccount).subscribe(res => {
        this.listApp = Helper.convertResApplication(res);
        resolve();
      });
    });
  }

  /**
   *getListTicketsWithReservations
   * @param appId
   * @returns
   */
  async getListTicketsWithReservations(appId: string): Promise<void> {
    return new Promise<void>(resolve => {
      this.ticketEditorService.getTickets(this.informationAccount, appId, null, true).subscribe(res => {
        this.listOfReservedTickets = res;
        resolve();
      });
    });
  }

  /**
   * Get name app display
   * @param value nameApp
   * @returns
   */
  changeDisplayApp(value: String): String {
    let temp = _.cloneDeep(value)?.toString();
    if (temp?.split('W')?.length > 7 && temp?.length > 20) {
      value = value?.substring(0, 19) + '...';
    } else if (value?.length > 35) {
      value = value?.substring(0, 33) + '...';
    }
    return value;
  }

  /**
   * selectTicket
   * @param ticket
   * @param e
   * @returns
   */
  async selectTicket(ticket: Ticket, e?): Promise<void> {
    if (!ticket) {
      return;
    }
    this.ticketSelected = ticket;
    if (this.targetYearAndMonth) {
      await this.chooseTargetYearAndMonth(this.informationAccount, this.ticketSelected.ticketId);
    }
  }

  /**
   * update config for picker
   *
   * @param period
   */
  private updateConfig(): void {
    this.config = {
      showWeekNumbers: false,
      format: this.FORMAT_DATE,
      firstDayOfWeek: 'mo',
      unSelectOnClick: false,
      locale: Helper.getLocale(this.languageKey)
    };
  }

  /**
   * chooseTargetYearAndMonth
   * @param informationAccount
   * @param ticketId
   * @param appIdSelected
   * @returns
   */
  async chooseTargetYearAndMonth(informationAccount: any, ticketId: number): Promise<void> {
    if (!informationAccount || !ticketId || !this.targetYearAndMonth) {
      return Promise.reject(null);
    }
    const dataDate = new Date(this.targetYearAndMonth);
    const year = dataDate.getFullYear().toString();
    const month = (dataDate.getMonth() + 1).toString();
    return new Promise<void>((resolve, reject) => {
      this.ticketEditorService.getListStockPartitions(informationAccount, ticketId, null, year, month).subscribe(
        async res => {
          if (!res || (res && res.length === 0)) {
            this.listStockPartitions = [];
            this.stockPartitionSelected = undefined;
            this.listStock = undefined;
            this.contentDaysMonth = _.cloneDeep(Helper.getOneMonthCalendar(this.selectedMonth, this.selectedYear, this.commonObject));
          } else {
            this.listStockPartitions = res;
            this.listStockPartitions = _.cloneDeep(this.listStockPartitions.sort(this.compareStockPartitions.bind(this)));
            await this.selectStockPartition(this.listStockPartitions[0]);
            this.tablelistReservationMaster.nativeElement.scrollTop = 0;
          }
          resolve();
        },
        error => {
          reject(error);
        }
      );
    });
  }

  /**
   * open date picker
   * @param time selected
   * @param isFrom true if open date picker time from
   */
  openDatePicker(picker: DatePickerDirective, time: any) {
    picker.api.open();
    this.addPseudoSpan();
    picker.api.moveCalendarTo(time ?? moment());
  }

  /**
   * add element date picker
   */
  addPseudoSpan(): void {
    while (document.getElementById('span-new')) {
      document.getElementById('span-new').remove();
    }
    return;
  }

  /**
   * formatToYearMonth
   * @param date
   * @returns
   */
  formatToYearMonth(date: string): string {
    const dateObj = new Date(date);
    return this.datePipe.transform(dateObj, 'yyyy/MM');
  }

  /**
   * onDateChange
   * @param e
   */
  async onDateChange(e?): Promise<void> {
    this.timeFromDisplay = this.formatToYearMonth(this.targetYearAndMonth);
    const dataDate = new Date(this.targetYearAndMonth);
    const year = dataDate.getFullYear();
    const month = dataDate.getMonth();
    this.selectedYear = _.cloneDeep(year);
    this.selectedMonth = _.cloneDeep(month);
    this.contentDaysMonth = _.cloneDeep(Helper.getOneMonthCalendar(this.selectedMonth, this.selectedYear, this.commonObject));
    await this.chooseTargetYearAndMonth(this.informationAccount, this.ticketSelected.ticketId);
  }

  /**
   * convertTimeDisplayLabel
   * @param dateString
   * @param languageKey
   * @returns
   */
  convertTimeDisplayLabel(dateString: string, languageKey: string): string {
    if (!dateString) {
      return '';
    }

    // Tách các phần của chuỗi ngày theo định dạng YYYY-MM-DD
    const parts = dateString.split('-');
    if (parts.length !== 3) {
      return '';
    }

    const [year, month, day] = parts.map(Number);
    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return '';
    }

    const date = new Date(year, month - 1, day); // JavaScript month is 0-based

    if (languageKey === 'jp') {
      return `${year}年${month}月 `;
    } else if (languageKey === 'en') {
      return `${date.toLocaleString('en-US', { month: 'long', year: 'numeric' })} `;
    }

    return ''; // Trả về chuỗi rỗng nếu languageKey không khớp
  }

  async selectStockPartition(stockPartition: StockPartition): Promise<void> {
    if (!stockPartition) {
      return;
    }
    this.stockPartitionSelected = stockPartition;
    const time = this.getStartAndEndDateOfMonth(this.contentDaysMonth);

    try {
      const res = await this.ticketEditorService
        .getStockByStockPartitionId(
          this.informationAccount,
          this.stockPartitionSelected.stockPartitionId,
          this.ticketSelected.ticketId,
          time.startDate,
          time.endDate
        )
        .toPromise();
      this.contentDaysMonth = _.cloneDeep(Helper.getOneMonthCalendar(this.selectedMonth, this.selectedYear, this.commonObject));
      if (!res || (res && !res.length)) {
        this.listStock = undefined;
      } else {
        this.listStock = res;
        this.contentDaysMonth.forEach(contentDay => {
          if (!contentDay.isOtherMonth) {
            const contentDate = this.datePipe.transform(contentDay.fullDate, 'yyyy-MM-dd');
            let isDataFound = false;

            this.listStock.forEach(stock => {
              const stockDate = stock.reserveOn.split('T')[0];

              if (stockDate.includes(contentDate)) {
                contentDay.maxReservationCount = stock.maxReservationCount;
                contentDay.dynamicPriceType = stock.dynamicPriceType;
                contentDay.isReserve = true;
                isDataFound = true;
              }
            });

            if (!isDataFound) {
              contentDay.isNoDataReserved = true;
            }
          }
        });
      }
    } catch (error) {}
  }

  /**
   * moveStockPartition
   * @param location
   * @returns
   */
  async moveStockPartition(location: number): Promise<void> {
    if (!this.listStockPartitions?.length) {
      return;
    }
    const currentIndex = this.listStockPartitions.findIndex(e => e.stockPartitionId == this.stockPartitionSelected.stockPartitionId);

    switch (location) {
      case Constant.START:
        if (this.listStockPartitions?.length != 1) {
          await this.selectStockPartition(this.listStockPartitions[0]);
          this.tablelistReservationMaster.nativeElement.scrollTop = 0;
        }
        break;

      case Constant.PREVIOUS:
        if (currentIndex > 0) {
          this.selectStockPartition(this.listStockPartitions[currentIndex - 1]);
        }
        break;

      case Constant.NEXT:
        if (currentIndex !== -1 && currentIndex < this.listStockPartitions.length - 1) {
          this.selectStockPartition(this.listStockPartitions[currentIndex + 1]);
        }
        break;

      case Constant.END:
        if (this.listStockPartitions?.length != 1) {
          await this.selectStockPartition(this.listStockPartitions[this.listStockPartitions.length - 1]);
          this.tablelistReservationMaster.nativeElement.scrollTop = this.tablelistReservationMaster.nativeElement.scrollHeight;
        }
        break;

      default:
        break;
    }
  }

  /**
   * importReservation
   * @returns importReservation
   */
  private importReservation(): void {
    // if (!this.appIdSelected) {
    //   this.dialogService.showDialog(DialogMessageComponent, {
    //     data: {
    //       title: this.translateService.instant('dialog-error.title'),
    //       text: this.translateService.instant('ticket-editor.choose-app')
    //     }
    //   });
    //   return;
    // }
    // if (!this.ticketSelected) {
    //   this.dialogService.showDialog(DialogMessageComponent, {
    //     data: {
    //       title: this.translateService.instant('dialog-error.title'),
    //       text: this.translateService.instant('ticket-editor.ticket-tab.choose-ticket')
    //     }
    //   });
    //   return;
    // }
    // if (!this.targetYearAndMonth) {
    //   this.dialogService.showDialog(DialogMessageComponent, {
    //     data: {
    //       title: this.translateService.instant('dialog-error.title'),
    //       text: this.translateService.instant('ticket-editor.msg.the-target-year-and-month-categories-are-empty')
    //     }
    //   });
    //   return;
    // }
    let element = document.getElementById('importedFileScheduleReservation') as HTMLInputElement;
    element.setAttribute('accept', '.xlsx');
    element.click();
  }

  /**
   * exportReservation
   */
  async exportReservation(): Promise<void> {
    this.dialogService.showDialog(
      DialogExportReservationComponent,
      {
        data: {
          informationAccount: this.informationAccount,
          appIdSelected: this.appIdSelected,
          ticketIdSelected: this.ticketSelected?.ticketId,
          targetYearAndMonth: this.targetYearAndMonth ? this.targetYearAndMonth : null
        }
      },
      async result => {
        if (!result) {
          return;
        }
        const date = new Date(result.targetYearAndMonth);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        try {
          const base64String = await this.ticketEditorService
            .exportReservation(this.informationAccount, result.ticketId, year, month)
            .toPromise();

          // Kiểm tra tính hợp lệ của chuỗi Base64
          if (!this.isValidBase64(base64String)) {
            throw new Error('Invalid Base64 string');
          }

          // Chuyển đổi chuỗi Base64 thành Blob
          const byteCharacters = atob(base64String);
          const byteArray = new Uint8Array(byteCharacters.length);
          byteArray.set(Array.from(byteCharacters, char => char.charCodeAt(0)));
          const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

          // Đọc dữ liệu từ Blob
          const reader = new FileReader();
          reader.onload = event => {
            const data = new Uint8Array(event.target.result as ArrayBuffer);
            const workbook = XLSX.read(data, { type: 'array' });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            // Lấy giá trị từ ô C1, C2, C3
            const no = worksheet['C1'] ? worksheet['C1'].v : '';
            const suffix = worksheet['C2'] ? worksheet['C2'].v : '';
            const ticketId = worksheet['C3'] ? worksheet['C3'].v : '';

            const fileName = `${ticketId}${`_`}${no}${`_`}${suffix}${`_`}${this.getCurrentDateFormatted()}${`.xlsx`}`;
            saveAs(blob, fileName);
          };
          reader.readAsArrayBuffer(blob);
        } catch (error) {
          console.error('Error during exportReservation:', error);
        }
      }
    );
  }

  /**
   * getCurrentDateFormatted
   * @returns
   */
  getCurrentDateFormatted(): string {
    const date = new Date();

    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  /**
   * isValidBase64
   * @param base64String
   * @returns
   */
  isValidBase64(base64String: string): boolean {
    const base64Pattern = /^(?:[A-Za-z0-9+\/]{4})*?(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/;
    return base64Pattern.test(base64String);
  }

  /**
   * convertFileToBase64
   * @param file
   * @returns
   */
  convertFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result as string);
      };
      reader.onerror = error => {
        reject(error);
      };
    });
  }

  /**
   * getStartAndEndDateOfMonth
   * @param contentDaysMonth
   * @returns
   */
  getStartAndEndDateOfMonth(contentDaysMonth: ContentDayReservation[]): { startDate: string; endDate: string } {
    const daysOfMonth = contentDaysMonth.filter(day => !day.isOtherMonth);
    if (daysOfMonth?.length === 0) {
      return { startDate: '', endDate: '' };
    }
    const startDate = this.convertDateToFormatYYYYMMDDD(new Date(daysOfMonth[0].fullDate));
    const endDate = this.convertDateToFormatYYYYMMDDD(new Date(daysOfMonth[daysOfMonth.length - 1].fullDate));

    return { startDate, endDate };
  }

  /**
   * convertDateToFormatYYYYMMDDD
   * @param date
   * @returns
   */
  convertDateToFormatYYYYMMDDD(date: Date): string {
    if (!date) {
      return '';
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date
      .getDate()
      .toString()
      .padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  /**
   * checkThepriceTable
   * @returns
   */
  checkThepriceTable() {
    if (
      !this.ticketSelected ||
      !this.targetYearAndMonth ||
      !this.listStockPartitions ||
      (this.listStockPartitions && this.listStockPartitions.length == 0)
    ) {
      return;
    }
    const date = new Date(this.targetYearAndMonth);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    this.ticketEditorService
      .reservationPrices(
        this.informationAccount,
        null,
        this.ticketSelected.ticketId,
        year,
        month,
        this.stockPartitionSelected.reservationClassId
      )
      .subscribe(res => {
        this.dialogService.showDialog(
          PriceTableComponent,
          {
            data: {
              listReservationPrices: res,
              ticketSelected: this.ticketSelected,
              targetYearAndMonth: this.convertTimeDisplayLabel(this.targetYearAndMonth, this.languageKey)
            }
          },
          async result => {
            if (!result) {
              return;
            }
          }
        );
      });
  }

  /**
   * getLabelTableDetailByLanguage
   * @param property
   * @param language
   * @returns
   */
  getLabelTableDetailByLanguage(property: string, language: any): string {
    switch (property) {
      case 'reservationClassName':
        return `${this.translateService.instant('ticket-editor.reservation.price-class-name-language-name')}${`_`}${
          this.languageKey == 'jp' ? language?.language_name_ja : language?.language_name_en
        }`;
      case 'name':
        return `${this.translateService.instant('ticket-editor.reservation.event-name')}${`_`}${
          this.languageKey == 'jp' ? language?.language_name_ja : language?.language_name_en
        }`;
      case 'stockPartitionDescription':
        return `${this.translateService.instant('ticket-editor.reservation.explanation-language-name')}${`_`}${
          this.languageKey == 'jp' ? language?.language_name_ja : language?.language_name_en
        }`;
      default:
        break;
    }
  }

  /**
   * getValueDetailByLanguage
   * @param value
   * @param languageCode
   * @returns
   */
  getValueDetailByLanguage(value: any, languageCode: string): string {
    if (!value) {
      return '';
    }
    if (!value[languageCode]) {
      return '';
    }
    return value[languageCode];
  }

  /**
   * compareStockPartitions
   * @param a
   * @param b
   * @returns
   */
  compareStockPartitions(a: StockPartition, b: StockPartition): number {
    if (a.defaultTime < b.defaultTime) return -1;
    if (a.defaultTime > b.defaultTime) return 1;

    if (a.tripId < b.tripId) return -1;
    if (a.tripId > b.tripId) return 1;

    if (a.reservationClassId < b.reservationClassId) return -1;
    if (a.reservationClassId > b.reservationClassId) return 1;

    return 0;
  }

  /**
   * checkTheMappedDataContentDay
   * @returns
   */
  checkTheMappedDataContentDay() {
    return this.contentDaysMonth.some(e => e?.isReserve || e?.isNoDataReserved);
  }
}
